/* FetchFiles.css */

.container {
    text-align: center;
}

.button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.button {
    cursor: pointer;
    font-size: 24px;
    margin: 0 10px;
    border: 2px solid #28a745;
    border-radius: 5px;
    padding: 10px;
    transition: background-color 0.3s, color 0.3s;
}

.button-active {
    background-color: #28a745;
    color: #ffffff;
}

.button1 {
    background-color: #f4a261;
    /* Light orange */
    color: black;
    border: 1px solid #f4a261;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.button-active1 {
    background-color: #ff0000;
    /* Red */
    color: white;
    border: 1px solid #ff0000;
}

.button1:disabled {
    background-color: #e2e2e2;
    /* Disabled state color */
    border: 1px solid #d4d4d4;
    cursor: not-allowed;
}


.table-container {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
    margin-top: 20px;
}

.table-container th,
.table-container td {
    border-bottom: 1px solid #ddd;
    padding: 8px;
}

.loading {
    font-size: 18px;
}


/* Add this CSS to your styles */
.select-container {
    display: flex;
    justify-content: flex-end;
    /* Aligns the content to the right */
    margin-bottom: 20px;
    /* Optional spacing */
}

.select-container label {
    margin-right: 10px;
    /* Space between label and select dropdown */
}